@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8FB !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plain-link {
  text-decoration: underline;
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h1 {
    font-weight: 500 !important;
}

h6 {
    font-weight: 400 !important;
    color: #A6ACBE;
}
.sidebar-container {
    position: fixed;
    width: 215px;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: #373B53;
    color: #fff;
}

.sidebar-logo {
    margin-left: 5px;
    margin-right: 20px;
}

.sidebar-navigation {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: relative;
}

.sidebar-navigation li {
    background-color: transparent;
    position: relative;
    display: inline-block;
    width: 100%;
    line-height: 20px;
}

.sidebar-navigation li a {
    padding: 10px 15px 10px 30px;
    display: block;
    color: #fff;
}

.sidebar-navigation li .sidebar-item-logo {
    margin-right: 10px;
}

.sidebar-navigation li a:active,
.sidebar-navigation li a:hover,
.sidebar-navigation li a:focus {
    text-decoration: none;
    outline: none;
}

.sidebar-navigation li::before {
    background-color: #0C8AFF;
    position: absolute;
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    transition: width 0.2s ease-in;
    width: 3px;
    z-index: -1;
}

.sidebar-navigation li:hover::before {
    width: 100%;
}

/*.sidebar-navigation .sidebar-header {*/
/*    font-size: 12px;*/
/*    text-transform: uppercase;*/
/*    background-color: #004687;*/
/*    padding: 10px 15px 10px 30px;*/
/*}*/

/*.sidebar-navigation .sidebar-header::before {*/
/*    background-color: transparent;*/
/*}*/

.content-container {
    padding-left: 215px;
}
.header-logo {
    margin: 0;
    width: 215px;
    padding: 15px 15px 10px 30px;
    font-size: 20px;
    background-color: #6F52ED;
    height: 60px;
    color: #fff;
    min-width: 215px;
}
.alert-container {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 400px;
}
